import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  constructor(private snackbar: MatSnackBar, private translate: TranslateService) { }

  message(text: string) {
    this.translate.get(text).pipe(take(1)).subscribe(translated => this._showDefault(translated));
  }

  /**
   * Append a piece of text after translated text.
   * @param text text that will be translated
   * @param extra text that will not be translated
   */
  messageWithExtra(text: string, extra: string) {
    this.translate.get(text).pipe(take(1)).subscribe(translated => this._showDefault(translated + ' ' + extra));
  }

  _showDefault(text) {
    this.snackbar.open(text, 'Ok', { duration: 4000, verticalPosition: 'top' });
  }
}
