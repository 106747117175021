import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
// import { MatChipsModule } from '@angular/material/chips';
import { ColorInputComponent } from './color-input/color-input.component';
import { ColorFieldComponent } from './color-field/color-field.component';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { PlantIconModule } from '../plant-icon/plant-icon.module';
import { ConfirmComponent } from './confirm/confirm.component';
import { TranslateModule } from '@ngx-translate/core';
import { BloomingComponent } from './blooming/blooming.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { SliderComponent } from './slider/slider.component';
import { IncludesPipe } from './shared.pipes';
import { MatNativeDateModule } from '@angular/material/core';
import { MiniPlantComponent } from './mini-plant/mini-plant.component';
import { SvgbarComponent } from './svgbar/svgbar.component';
import { LocationDebugComponent } from './location-debug/location-debug.component';
import { LanguageSelectComponent } from '../core/components/language-select/language-select.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ReportNowComponent } from './report-now/report-now.component';
import { RouterModule } from '@angular/router';
import { SupportersComponent } from './supporters/supporters.component';


@NgModule({
  declarations: [
    ColorInputComponent,
    ColorFieldComponent,
    ConfirmComponent,
    BloomingComponent,
    SliderComponent,
    MiniPlantComponent,
    LanguageSelectComponent,

    IncludesPipe,
    SvgbarComponent,
    LocationDebugComponent,
    ReportNowComponent,
    SupportersComponent,
  ],
  imports: [
    // For the components/declarations in here, these are required
    CommonModule,
    TranslateModule.forChild(),
    ReactiveFormsModule,
    RouterModule,

    // Material (this could be moved to a separate material module)
    MatButtonModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatCardModule,
    MatListModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    // MatChipsModule,

    PlantIconModule,
    NgxSliderModule,
  ],
  exports: [
    MatButtonModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatCardModule,
    MatListModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatRadioModule,
    // MatChipsModule,

    PlantIconModule,

    ColorInputComponent,
    ColorFieldComponent,
    ConfirmComponent,
    BloomingComponent,
    SliderComponent,
    MiniPlantComponent,
    LanguageSelectComponent,

    IncludesPipe,
    SvgbarComponent,
    LocationDebugComponent,

    ReportNowComponent,
    SupportersComponent,

  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { floatLabel: 'always', appearance: 'fill' },
    },
  ],
})
export class SharedModule { }
