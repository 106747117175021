import { createSelector } from '@ngrx/store';
import { State } from '../reducers';
import { PlantlistGroupSetting, PlantSearchFilter } from '../../modules/plants/helpers';
import { plantCatalogueMapper, plantMapper } from '../../modules/plants/helpers/plant-mapper';
import { PlantListGrouper } from '../../modules/plants/helpers';
import { PlantSortSetting, Sorter, SortVariant } from '../../modules/plants/helpers/sorting';
import { PlantDetailsSchema, PlantSchema } from '../../modules/plants/types';
import { AppSelectors } from '../app/selectors';

export const plantsStateSelector = (state: State) => state.plants;

export class PlantsSelectors {

  //
  // Basic plants selectors, reading from local module state
  //
  static filters = createSelector(plantsStateSelector, AppSelectors.siteSetting, (state, siteSetting) => ({
    ...state.filters,
    ignoreGroup: PlantSearchFilter.hasQuery(state.filters),
    onylBeeplants: siteSetting === 'pollen'
  }));
  static groupSetting = createSelector(plantsStateSelector, (state) => state.groupSetting);
  static sortSetting = createSelector(plantsStateSelector, (state) => state.sortSetting);
  static plants = createSelector(plantsStateSelector, (state) => state.plants);
  static plantListCacheFlag = createSelector(
    plantsStateSelector,
    (state) => state.plantListCacheFlag
  );
  static plantDetail = createSelector(plantsStateSelector, (state) => state.plantDetail);
  static plantListNetworkState = createSelector(
    plantsStateSelector,
    (state) => state.plantListLoading
  );
  static plantDetailNetworkState = createSelector(
    plantsStateSelector,
    (state) => state.plantDetailLoading
  );

  //
  // Selectors that return augmentied plants data with date/time (today) and localized (userRegions) data
  // @see `plantMapper`
  //
  static extendedPlants = createSelector(
    PlantsSelectors.plants,
    AppSelectors.userRegion,
    AppSelectors.today,
    AppSelectors.plantnames,
    (plants, location, today, plantnames) => plantCatalogueMapper(plants, location, today, plantnames)
  );

  // Filter out entires that have a prevalence of zero for the specific location
  // While `extendedPlants` can be useful for lookups (e.g. in existing) reports,
  // this list filters out plants with zero prevalence. It should be used as the
  // basis for catalogue lists.
  static extendedPlantsLimitedByRegion = createSelector(PlantsSelectors.extendedPlants, plants => plants.filter(plant => plant.prevalence > 0))

  static plantExtendedDetail = createSelector(
    PlantsSelectors.plantDetail,
    AppSelectors.userRegion,
    AppSelectors.today,
    AppSelectors.plantnames,
    (plant, location, today, plantnames) =>
      plant ? plantMapper<PlantDetailsSchema>(plant, location, today, plantnames) : null
  );

  static plantWithNetworkState = createSelector(
    PlantsSelectors.plantDetailNetworkState,
    PlantsSelectors.plantExtendedDetail,
    (nwstate, plant) => ({
      loadingState: nwstate,
      plant,
    })
  );

  static filteredPlants = createSelector(
    PlantsSelectors.extendedPlantsLimitedByRegion,
    PlantsSelectors.filters,
    (plants, filters) => PlantSearchFilter.filter(plants, filters)
  );

  /**
   * Some filter settings can override the sort setting.
   */
  static appliedSortSetting = createSelector(
    PlantsSelectors.sortSetting,
    PlantsSelectors.filters,
    (sortSetting, filters) =>
      filters.ignoreGroup ? { key: SortVariant.searchRank, direction: 'desc' } as PlantSortSetting : sortSetting
  );

  static sortedPlants = createSelector(
    PlantsSelectors.filteredPlants,
    PlantsSelectors.appliedSortSetting,
    (plants, sortSetting) => Sorter.sort(plants, sortSetting)
  );

  /**
   * Some filter settings can override the group setting.
   */
  static appliedGroupSetting = createSelector(
    PlantsSelectors.groupSetting,
    PlantsSelectors.filters,
    (groupSetting, filters) => (filters.ignoreGroup ? PlantlistGroupSetting.none : groupSetting)
  );

  static plantGroups = createSelector(
    PlantsSelectors.sortedPlants,
    PlantsSelectors.appliedGroupSetting,
    (plants, groupSetting) => PlantListGrouper.group(plants, groupSetting)
  );

  static viewSetting = createSelector(
    plantsStateSelector,
    (state) => state.viewSetting
  );
}
