export const variables = {
  availableErrorStatuses: ['404', '500', '504'],
};

export const LANGUAGES = [
  { code: 'da', localName: 'Dansk' },
  { code: 'en', localName: 'English' },
  { code: 'fr', localName: 'Français' },
  { code: 'lv', localName: 'Latviešu' },
  { code: 'nl', localName: 'Nederlands' },
  { code: 'no', localName: 'Norsk' },
];
