import {
  PlantSchema,
  PlantSchemaLocalized,
  PlantBloomingPeriod,
  PlantDetailsSchema,
  PlantPrevalence,
  LocationObject,
  PlantType,
} from '../types';
import { UserRegion } from '../../../types/user-location';
import { format, getDayOfYear } from 'date-fns';

/**
 * Augments a plant model, as it is retrieved form the backend, with new datafields
 * that are calculated for instance from current location and date.
 * A plant will become a 'localized plant', with the matching settings already set
 * where there are more options (blooming times, commonality).
 *
 * @param plant the initial plant as returned by api
 * @param location the location objetct from state
 * @param currentDate optinal, a current date (defaults to NOW())
 */
export function plantMapper<T = PlantSchema | PlantDetailsSchema>(
  plant: PlantSchema | PlantDetailsSchema,
  location: UserRegion,
  currentDate?: Date,
  plantnames: {latin, local}[] = []
): T & PlantSchemaLocalized {
  currentDate = currentDate || new Date();
  const bloomingPeriod = matchBlooming(plant.bloomingPeriods, location);
  const localizedBlooming = bloomingPeriod
    ? calculateBloomingState(bloomingPeriod, currentDate)
    : {};
  const prevalence = matchPrevalence(plant.prevalences, location);
  const translateFromLatin = plantnames.find(tr => tr.latin === plant.species)?.local;
  return {
    ...plant,
    name: translateFromLatin || plant.name,
    bloomingPeriod,
    ...localizedBlooming,
    prevalence,
    plantType: plant.plantType || PlantType.Unknown,
  } as T & PlantSchemaLocalized;
}

/**
 * Localize a whole catalogue of plants.
 * @param plants
 * @param location
 * @param currentDate
 * @param plantnames
 * @returns
 */
export function plantCatalogueMapper<T = PlantSchema | PlantDetailsSchema>(
  plants: Array<PlantSchema | PlantDetailsSchema>,
  location: UserRegion,
  currentDate?: Date,
  plantnames: {latin, local}[] = []
): Array<T & PlantSchemaLocalized> {
  return plants.map((plant) => plantMapper<T>(plant, location, currentDate, plantnames));
}


interface BloomingState {
  bloomingNow?;
  daysToBlooming?;
  blmStartMth?;
  daysToBloomingEnd?;
  blmStartMthRelative?: number;
}

/**
 * Calculate blooming state - blooming period in relation to a concrete date.
 * @param bloomingPeriod the blooming period
 * @param currentDate the current date
 */
export function calculateBloomingState(
  bloomingPeriod: PlantBloomingPeriod,
  currentDate
): BloomingState {
  const fromDate = new Date(bloomingPeriod.bloomingStartDate);
  const todayDate = new Date(currentDate);
  const from = getDayOfYear(fromDate);
  const to = getDayOfYear(new Date(bloomingPeriod.bloomingEndDate));
  const cur = getDayOfYear(todayDate);

  let bloomingNow;

  if (from < to) {
    // from/to setting is within a year
    bloomingNow = from <= cur && cur <= to;
  } else {
    // from/to setting spans the endof year
    bloomingNow = cur <= to || cur >= from;
  }
  const toStart = from - cur;
  const toEnd = to - cur;
  const daysToBlooming = !bloomingNow
    ? toStart > 0
      ? toStart
      : toStart + 365
    : toStart < 0
    ? toStart
    : toStart - 365;

  const daysToBloomingEnd = bloomingNow ? (toEnd > 0 ? toEnd : toEnd + 365) : undefined;
  const blmStartMth = format(fromDate, 'LLL');

  const mDiff = fromDate.getMonth() - todayDate.getMonth();
  const blmStartMthRelative = mDiff < 0 ? mDiff + 12 : mDiff;

  return { bloomingNow, daysToBlooming, daysToBloomingEnd, blmStartMth, blmStartMthRelative };
}

/**
 * From the location, determine the most suitable blooming data.
 */
function matchBlooming(bperiods: PlantBloomingPeriod[], location: UserRegion) {
  return matchByLocation(bperiods, location);
}

/**
 * From the location, determine the most suitable prevalences data
 */
function matchPrevalence(prevalences: PlantPrevalence[], location: UserRegion) {
  const matched = matchByLocation(prevalences, location);
  return matched ? matched.prevalence : null;
}

/**
 * Return the data object that matches the most "detailed" (low level) location
 * of the user location
 * @param data list of location-containing data objects.
 * @param location the user location as reference location.
 */
function matchByLocation(data: LocationObject[], location: UserRegion) {
  if (!data || data.length === 0 || !location) {
    return null;
  }
  let selected;
  const hierarchicalLocationIds = [location.locationId].concat(location.locationParents);
  for (const locId of hierarchicalLocationIds) {
    selected = data.find((item) => item.location && item.location.id === locId);
    if (selected) {
      return selected;
    }
  }
}
