import { FeatureMap } from "./feature-map";

export const environment = {
  version: '2.12.1',
  production: true,
  apiUrl: '/api',
  translate: { prefix: '/api/texts/strings/', suffix: '/' },
  namesTranslate: { prefix: '/api/texts/names/', suffix: '/' },
  // sentryDsn: 'https://92c72d139723dd14763e7a61b4aeb12a@o68717.ingest.sentry.io/4506518018654208',
  sentryDsn: '',
  forceHostname: false,
  gaTrackingCode: 'G-07HWSZ286T',
  features: {
    dateSelectable: false,
    shapeMap: false,
    manualRegion: false,
    rankingScore: false,
    suggestions: true,
    staff: false,
    showSupporters: true,

  } as FeatureMap,
  staffFeatures: {
    dateSelectable: false,
    shapeMap: true,
    manualRegion: true,
    rankingScore: true,
    suggestions: true,
    staff: true,
    showSupporters: true,
  } as FeatureMap
};
