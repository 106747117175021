import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
  withXsrfConfiguration,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { metaReducers, reducers } from './state/reducers';
import { effects } from './state/effects';
import { Router } from '@angular/router';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { CustomRouterStateSerializer } from './router-serializer';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from './HttpLoaderFactory';
import { GeolocationService } from '@ng-web-apis/geolocation';
import { CoreModule } from './modules/core/core.module';
import { NavigationStack } from './state/navigation/reducers';
import { AuthModule } from './modules/auth/auth.module';

import * as Sentry from '@sentry/angular';
import { MiscModule } from './modules/misc/misc.module';
import { MarkdownModule } from 'ngx-markdown';
import {
  IGoogleAnalyticsSettings,
  NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN,
  NgxGoogleAnalyticsModule,
} from 'ngx-google-analytics';

const mainProviders = [
  GeolocationService,
  {
    provide: RouterStateSerializer,
    useClass: CustomRouterStateSerializer,
  },

  // GA Tracking
  {
    provide: NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN,
    useValue: {
      trackingCode: environment.gaTrackingCode,
      commands: [],
      uri: undefined,
      enableTracing: undefined,
      nonce: undefined,
    } as IGoogleAnalyticsSettings,
  },
  provideHttpClient(
    withInterceptorsFromDi(),
    withXsrfConfiguration({
      cookieName: 'csrftoken',
      headerName: 'X-CSRFToken',
    })
  ),
];

const sentryProviders = [
  {
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler({
      showDialog: false,
    }),
  },
  {
    provide: Sentry.TraceService,
    deps: [Router],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: () => () => {},
    deps: [Sentry.TraceService],
    multi: true,
  },
];

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot(effects),
    BrowserAnimationsModule,
    StoreDevtoolsModule.instrument({
      logOnly: environment.production,
    }),
    StoreRouterConnectingModule.forRoot<NavigationStack>(),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
    }),
    MarkdownModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    // Initialise empty (and only later, use initializer manually).
    // https://github.com/maxandriani/ngx-google-analytics/issues/40
    NgxGoogleAnalyticsModule,
    // App Modules that are loaded right on startup
    CoreModule,
    AuthModule,
    MiscModule,
    AppRoutingModule,
  ],
  providers: environment.sentryDsn ? [...mainProviders, ...sentryProviders] : mainProviders,
})
export class AppModule {}
